import React from 'react'
import { Link } from 'gatsby'

import SEO from '../../components/seo'
import { Deco, default as Layout, Header, MainWithMenu, Section } from '../../components/layout'
import { ApplyAndWhatsNextSections, PositionIcon } from '../../components/pages/CareersPage'
import { Nav } from '../../components/nav'
import { LdJsonJobPosting, HeaderLink } from '../../components/semanticdata'
import Analytics from '../../components/analytics'

const CareerPositionPage = () => (
  <Layout className="bg-primary-1 text-primary-3">
    <Analytics />
    <SEO title="Android Developer" pageUrl="/careers/android/" type="article" cardImage="/img/tumbacardjobs2.png" description="Do you consider yourself an Android expert? R8, D8 doesn't sound like a Star Wars character to you, Let's Talk!" />
    <LdJsonJobPosting title="Android Developer" description="Do you consider yourself an Android expert? R8, D8 doesn't sound like a Star Wars character to you, Let's Talk!" minSalary="5500.00" maxSalary="7500.00" />
    <MainWithMenu className="relative grid">
      <Header className="z-20 pt-6 pb-24">
        <HeaderLink className="text-primary-2" />
        <Link to="/careers/">
          <div className="py-12 text-left">
            <h1 className="py-4 text-4xl lg:text-5xl text-primary-3 text-shadow">Careers</h1>
          </div>
        </Link>
      </Header>
      <Deco className="z-10 bg-primary-2" decoType={2} before after gridRow={2} />
      <Section className="z-20 lg:mr-48" gridRow={2}>
        <PositionIcon className="w-12 sm:w-20 text-primary-1 fill-current" />
        <h2 className="text-2xl md:text-3xl lg:text-4xl text-primary-1">Android Developer</h2>
        <div className="mt-4 mb-4 text-lg lg:text-xl tracking-normal font-normal">
          <p className="mt-4 md:mt-5 lg:mt-6">
            <strong className="text-primary-1 font-normal">You consider yourself an Android expert</strong>. R8, D8 doesn't sound like a Star Wars character to you. Romain Guy, Chet Haase, Tor Norbye and Jake Wharton are not just any other names to you.
          </p>
          <p className="mt-4 md:mt-5 lg:mt-6">We do too!</p>
          <p className="mt-4 md:mt-5 lg:mt-6">
            To all like-minded enthusiasts, <strong className="text-primary-1 font-normal">let's talk</strong> and explore together the Future ahead.
          </p>
          <p className="mt-4 md:mt-5 lg:mt-6">At Tumba Solutions we have a number of opportunities in our Android domains, including:</p>
          <ul className="pl-10 mt-2 list-disc">
            <li>Digital Publishing.</li>
            <li>Sustainable Urban mobility.</li>
            <li>Fleet management systems.</li>
          </ul>
          <p className="mt-4 md:mt-5 lg:mt-6">
            <em>What we are primarily looking for is a track record of building things that work and passion to make a better product.</em>
          </p>
        </div>
        <div className="grid gap-12 grid-cols-1 lg:grid-cols-2">
          <div>
            <h3 className="text-xl lg:text-2xl text-primary-1">Main Responsibilities</h3>
            <div className="mt-4 md:mt-5 lg:mt-6 text-lg lg:text-xl font-normal tracking-normal">
              <ul className="pl-10 list-disc">
                <li className="mt-2 md:mt-3 lg:mt-4">
                  <strong className="text-primary-1 font-normal">Collaborate</strong> with cross functional teams to define, design and ship new features.
                </li>
                <li className="mt-2 md:mt-3 lg:mt-4">
                  <strong className="text-primary-1 font-normal">Design and build</strong> a robust codebase that enables rapid exploration and evaluation of different approaches.
                </li>
                <li className="mt-2 md:mt-3 lg:mt-4">
                  Use your software development expertise for <strong className="text-primary-1 font-normal">inspiring others</strong> to develop better software practices and principles.
                </li>
                <li className="mt-2 md:mt-3 lg:mt-4">
                  <strong className="text-primary-1 font-normal">Guides</strong> junior developers and removes any impediments that they might encounter.
                </li>
                <li className="mt-2 md:mt-3 lg:mt-4">Help define test cases for development testing, verification and validation.</li>
                <li className="mt-2 md:mt-3 lg:mt-4">Help improve the product quality and user experience.</li>
                <li className="mt-2 md:mt-3 lg:mt-4">Be responsible for your work and take pride in it.</li>
              </ul>
            </div>
          </div>
          <div>
            <h3 className="text-xl lg:text-2xl text-primary-1">Core Requirements</h3>
            <div className="mt-4 md:mt-5 lg:mt-6 text-lg lg:text-xl font-normal tracking-normal">
              <ul className="pl-10 list-disc">
                <li className="mt-2 md:mt-3 lg:mt-4">
                  <strong className="text-primary-1 font-normal">5+ years</strong> of hands-on experience in <strong className="text-primary-1 font-normal">Android</strong> development.
                </li>
                <li className="mt-2 md:mt-3 lg:mt-4">
                  <strong className="text-primary-1 font-normal">Advanced knowledge</strong> in Android development with <strong className="text-primary-1 font-normal">Java</strong>, <strong className="text-primary-1 font-normal">Kotlin</strong>,{' '}
                  <strong className="text-primary-1 font-normal">Jetpack Compose</strong> and <strong className="text-primary-1 font-normal">coroutines</strong>.
                </li>
                <li className="mt-2 md:mt-3 lg:mt-4">
                  <strong className="text-primary-1 font-normal">Good understanding</strong> of the Android SDK (including Android Jetpack) and related UI design principles, guidelines, patterns and best practices.
                </li>
                <li className="mt-2 md:mt-3 lg:mt-4">
                  <strong className="text-primary-1 font-normal">Good understanding</strong> of software engineering principles.
                </li>
                <li className="mt-2 md:mt-3 lg:mt-4">
                  <strong className="text-primary-1 font-normal">Good understanding</strong> of the general mobile landscape, architectures, trends and emerging technologies.
                </li>
                <li className="mt-2 md:mt-3 lg:mt-4">
                  <strong className="text-primary-1 font-normal">Good understanding</strong> of the full mobile development life cycle and the experience of actively participating in project/product creation and distribution.
                </li>
                <li className="mt-2 md:mt-3 lg:mt-4">
                  <strong className="text-primary-1 font-normal">Good working knowledge</strong> of using CI/CD systems and pipelines.
                </li>
                <li className="mt-2 md:mt-3 lg:mt-4">Self-organized, proactive and good communicator.</li>
              </ul>
            </div>
          </div>
        </div>
      </Section>
      <ApplyAndWhatsNextSections position="Android Developer" active={true} />
      <Nav gridRow={7} className="z-20 text-primary-2" />
    </MainWithMenu>
  </Layout>
)

export default CareerPositionPage
